<template>
	<v-container fluid ma-0 pa-0>
	<v-toolbar flat color="blue-grey darken-1">
		<v-text-field v-model="filter" flat solo dense hide-details background-color="blue-grey darken-2" 
		prepend-inner-icon="search" append-icon="mdi-keyboard-return" clearable @click:clear="onSearchClear()"></v-text-field>  
		<v-btn class="mx-1 mt-0" color="blue-grey darken-4" @click.stop="onSearch()">Suchen</v-btn>
		<v-spacer></v-spacer>
		<v-spacer></v-spacer>
		<v-btn color="blue-grey darken-4" @click.stop="onEditItem()">Neu</v-btn>
	</v-toolbar>
    <v-data-table
		:headers="cfgLists"
		:items="filteredItems"
		item-key="id"
		:page.sync="page"
		:items-per-page="10"
		must-sort v-ripple
		:sort-by.sync="sortBy"
		:sort-desc.sync="sortDesc"
		class="blue-grey darken-3 table-cursor"
		hide-default-footer
		@click:row="onRowClick($event)"
    >
		<template v-slot:item.id="{ item }">
			<v-chip color="blue-grey" dark x-small>{{ item.id }}</v-chip>
		</template>
		<template v-slot:item.action="{ item }">
			<v-hover v-slot:default="{ hover }">
			<v-btn small fab color="blue-grey darken-4" :class="{ 'success': hover }" @click.stop="onEditItem(item.id)" :loading="false" :disabled="false">
				<v-icon>create</v-icon></v-btn></v-hover>
			<v-hover v-slot:default="{ hover }">
			<v-btn small fab color="blue-grey darken-4" :class="{ 'warning': hover }" @click.stop="onPrintItem(item.id)" :loading="false" :disabled="false">
				<v-icon small>print</v-icon></v-btn></v-hover>
		</template>
		<template slot="footer">
			<div class="text-xs-center pt-2">
				<v-pagination color="blue-grey" v-model="page" :length="pages" :total-visible="7" @input="onPageChange"></v-pagination>
			</div>
		</template>
    </v-data-table>
    <GenericForm v-if="edit" :tablename="tablename" :reftable="modulData.meta.tablename" :model="item" @cancelled="editCancelled()" @submitted="editSubmitted()" />
	</v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import GenericForm from './GenericForm'
export default {
	name: 'GenericList',
	components: {
		GenericForm,
	},
	props: {
		modulData: {
			type: Object,
			required: true,
			default: null,
		},
	},
	data: () => ({
			page: 1,
			pages: 1,
			sortBy: 'id',
			sortChangeTime: null,
			sortDesc: true,
			filter: '',
			cfgTable: {},
			cfgLists: [],
			item: {},
			edit: false,
    }),
	computed: {
		...mapGetters({
			items: 'generic/getItems',
		}),
		filteredItems: function () {
			let self = this
			if(!self.items.length) {return []}
			if(!self.searchQuery || self.searchQuery.length < 3) {
				return self.items
			} else {
				return self.items.filter(function (item) {
					let searchRegex = new RegExp(self.searchQuery, 'i')
					return searchRegex.test(item.name)
				})
			}
		},
		tablename() {
			return this.modulData.meta.subtable
		},
	},
	methods: {
		fetchData () {
			this.loading = true
			let param = {
				tablename: this.modulData.meta.subtable,
				parentref: this.modulData.meta.refname,
				parentid: this.modulData.params.id,
				page: this.page,
				sortBy: this.sortBy,
				sortDesc: (this.sortDesc?'desc':'asc'),
				filter: this.filter,
			}
			let self = this
			this.$store.dispatch('generic/getItems', param)
			.then((response) => {
				self.page = response.data.current_page
				self.pages = response.data.last_page
				self.loading = false
				console.log('filteredItems:', self.filteredItems)
			})
			.catch((error) => {
				console.log(error)
				self.loading = false
			});
		},
		getCfgs() {
			let param = {
				table_name: this.modulData.meta.subtable,
				reftable: this.modulData.meta.tablename,
			}
			if(this.reftable == 'none') { param.reftable = this.tablename }
			this.cfgTable = this.$store.getters['core/getCfgTable'](param)
			this.cfgLists = this.$store.getters['core/getCfgLists'](param)
			//this.cfgLists.push({ text: 'Open', value: 'open', align: 'center', sortable: false, width: 25, })
			this.cfgLists.push({ text: 'Action', value: 'action', align: 'center', sortable: false, width: 25, })
			console.log('genericList cfgTable:', this.cfgTable)
			console.log('genericList cfgLists:', this.cfgLists)
		},
		onSearch () {
			this.fetchData ()
			console.log(this.items)
			console.log(this.filteredItems)
		},
		onSearchClear () {
			this.filter = ''
			this.fetchData ()
		},
		onPageChange () {
			this.fetchData ()
		},
		onPrintItem (index) {
			alert(index)
		},
		onEditItem (id = null) {
			if(id && id >= 0) {
				this.item = Object.assign({}, this.filteredItems.find(x => x.id === id))
				console.log("XMODEL:", this.item)
			} else {
				this.item = {}
				this.item[this.modulData.meta.refname] = this.modulData.params.id
				this.item[this.modulData.name] = this.modulData.meta.item
			}
			this.edit = true
		},
		editCancelled () {
			this.edit = false
			this.snack = {active: true, type: 'warning', text: 'cancelled'}
		},
		editSubmitted() {
			this.fetchData ()
			this.edit = false
			this.snack = {active: true, type: 'success', text: 'saved'}
		},
		onSortChange () {
			this.$nextTick(() => {
				this.fetchData ()
			})
		},
		onRowClick(e) {
			console.log('clicked:', e)
			//const item = e
			//this.$router.push({ name: this.cfgTable.model_name, params: { id: item.id } })
		},
	},
	mounted() {
		this.getCfgs()
		this.fetchData()
	},
	watch: {
		sortBy () {
			if(Date.now() - this.sortChangeTime > 50) setTimeout(this.onSortChange (), 50)
			this.sortChangeTime = Date.now()
		},
		sortDesc () {
			if(Date.now() - this.sortChangeTime > 50) setTimeout(this.onSortChange (), 50)
			this.sortChangeTime = Date.now()
		},
	},
};
</script>
<style scoped>
.selected {
    background-color: red
}
.table-cursor >>> tbody tr:hover {
	cursor: pointer;
}
</style>