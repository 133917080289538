var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","ma-0":"","pa-0":""}},[_c('v-toolbar',{attrs:{"flat":"","color":"blue-grey darken-1"}},[_c('v-text-field',{attrs:{"flat":"","solo":"","dense":"","hide-details":"","background-color":"blue-grey darken-2","prepend-inner-icon":"search","append-icon":"mdi-keyboard-return","clearable":""},on:{"click:clear":function($event){return _vm.onSearchClear()}},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('v-btn',{staticClass:"mx-1 mt-0",attrs:{"color":"blue-grey darken-4"},on:{"click":function($event){$event.stopPropagation();return _vm.onSearch()}}},[_vm._v("Suchen")]),_c('v-spacer'),_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue-grey darken-4"},on:{"click":function($event){$event.stopPropagation();return _vm.onEditItem()}}},[_vm._v("Neu")])],1),_c('v-data-table',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"blue-grey darken-3 table-cursor",attrs:{"headers":_vm.cfgLists,"items":_vm.filteredItems,"item-key":"id","page":_vm.page,"items-per-page":10,"must-sort":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":function($event){return _vm.onRowClick($event)}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"blue-grey","dark":"","x-small":""}},[_vm._v(_vm._s(item.id))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{class:{ 'success': hover },attrs:{"small":"","fab":"","color":"blue-grey darken-4","loading":false,"disabled":false},on:{"click":function($event){$event.stopPropagation();return _vm.onEditItem(item.id)}}},[_c('v-icon',[_vm._v("create")])],1)]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{class:{ 'warning': hover },attrs:{"small":"","fab":"","color":"blue-grey darken-4","loading":false,"disabled":false},on:{"click":function($event){$event.stopPropagation();return _vm.onPrintItem(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("print")])],1)]}}],null,true)})]}}])},[_c('template',{slot:"footer"},[_c('div',{staticClass:"text-xs-center pt-2"},[_c('v-pagination',{attrs:{"color":"blue-grey","length":_vm.pages,"total-visible":7},on:{"input":_vm.onPageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])],2),(_vm.edit)?_c('GenericForm',{attrs:{"tablename":_vm.tablename,"reftable":_vm.modulData.meta.tablename,"model":_vm.item},on:{"cancelled":function($event){return _vm.editCancelled()},"submitted":function($event){return _vm.editSubmitted()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }